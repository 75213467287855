import { navigate, PageProps } from "gatsby"
import React, { ReactElement } from "react"
import { Layout, SEO } from "../components"
import { Button } from "../components/common/button"
import AuthGuard from "../middleware/AuthGuard"
import * as styles from "./reservation-result.module.scss"

interface PageContext {
  success: boolean
}

const ReservationResultPage = (
  props: PageProps<void, PageContext>
): ReactElement => {
  const { success } = props.pageContext

  return (
    <AuthGuard>
      <Layout>
        <SEO title="予約結果" lang="ja" />
        <div className={styles.mainContainer}>
          {success ? <Success /> : <Failure />}
        </div>
      </Layout>
    </AuthGuard>
  )
}

export default ReservationResultPage

const Success = (): ReactElement => {
  return (
    <div className={styles.container}>
      <section>
        <h2 className={styles.title}>入庫が確定されました</h2>
        <div className={styles.body}>
          ご利用ありがとうございます。
          <ul>
            <li>
              入庫時刻から通常料金が課金され、予約料金と併せてご精算となります。
            </li>
            <li className={styles.attention}>
              料金精算は、QT-netにご登録のクレジットカードでの自動精算がご利用いただけますので、精算機を使わずそのままご出庫ください。
            </li>
            <li>
              自動精算の結果は【コインパ予約】利用履歴メニューよりご確認いただけます。領収書もPDF形式でシェア可能です。
            </li>
            <li>
              精算機で精算される場合は、【コインパ予約】利用履歴には時間及び料金は表示されませんので、必ず領収書をお取りください。
            </li>
          </ul>
        </div>
      </section>
      <div className={styles.footer}>
        <Button layout="fill" onClick={() => navigate("/reservation-detail")}>
          予約ステータスへ
        </Button>
      </div>
    </div>
  )
}

const Failure = (): ReactElement => {
  return (
    <div className={styles.container}>
      <section>
        <h2 className={styles.title}>入庫していません</h2>
        <div className={styles.body}>
          <p className={styles.attention}>大変申し訳ございません。 </p>
          <p className={styles.attention}>
            予約車室に他の車両が入庫してしまいました。
          </p>
          <ul>
            <li>
              お客様の予約は自動的にキャンセルとなりますので、再度予約をお願いします。
            </li>
            <li>ここまでの料金はいっさい発生しておりません。</li>
            <li>
              再予約は、「再予約」ボタンまたは【コインパ予約】利用履歴メニューからが便利です。
            </li>
          </ul>
        </div>
      </section>
      <div className={styles.footer}>
        <Button layout="fill" onClick={() => navigate("/")}>
          検索画面へ
        </Button>
      </div>
    </div>
  )
}
